import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SCREENSEQUENCE, countries } from '../../Constant';
import { Col, Container, Form, Row, Table } from 'react-bootstrap';
import { convertToCapital } from '../../utils/helper';

function CustomModal(props) {
    const { user, subject, setForm, form  } = props;

    const [message,setMessage] = useState();
    const [title, setTitle] = useState();
    
    const handleSave = () => {
        if(subject){
            setForm({subject:title,body: message})
        }else{
            setForm(message)
        }
    };

    return (
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        //   fullscreen="xxl-down"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {user.userName}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>Group {user.group}</h4>
            <Form>
                {
                    subject &&
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email Subject</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="In Dino App"
                            maxLength={50}
                            autoFocus
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Form.Group>
                }
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                    >
                    <Form.Label>{subject ? 'Email Body' : 'Notification Text'}</Form.Label>
                    <Form.Control as="textarea" onChange={(e) => setMessage(e.target.value)} rows={3} />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button variant="primary" onClick={handleSave}>
                Send
            </Button>
        </Modal.Footer>
        </Modal>
    );
}

export default CustomModal;