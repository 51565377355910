import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.css'; 
import { Tab, Tabs } from 'react-bootstrap';

import Header from './Header';


import { sendNotification, getUsers, sendEmail } from './../../utils/api';
import { EMAIL_MESSAGE, EMAIL_SUBJECT, GROUPS, NOTIFICATION_MESSAGE } from '../../Constant';
import List from './List';
import DetailModel from '../DetailModal/DetailModal';
import { validateEmail } from '../../utils/helper';
import CustomModal from '../CustomModal/CustomModal';

const Dashboard = ({ setIsAuthenticated }) => {
  const [users, setUsers] = useState([]);
  const [detail, setDetail] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [notificationModalShow, setNotificationModalShow] = useState(false);
  const [emailModalShow, setEmailModalShow] = useState(false);
  const [notification, setNotificaion] = useState();
  const [email, setEmail] = useState({
    subject:null,
    body: null
  });

  const [key, setKey] = useState('group1');
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sendList, setSendList] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      const list = await getUsers();
      if (list.status !== 'success') {
        Swal.fire({
          icon: 'error',
          title: 'Invalid request',
          text: `${list.message} `,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setUsers(list.users);
        Swal.fire({
          icon: 'success',
          title: 'Data fetched!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    fetchUsers();
    //   const data = JSON.parse(localStorage.getItem('users_data'));
    // if (data !== null && Object.keys(data).length !== 0) setUsers(data);
  }, []);

  useEffect(() => {
    if(email.body && email.subject){
      sendEmailById(detail.email,email);
    }
  },[email])

  useEffect(() => {
    if(notification){
      console.log(detail,notification);
      sendNotificationById(detail.fcmToken,notification);
    }
  },[notification]);

  const sendEmailById = async (email,emailObj) => {
    const mailObj = {
      email: email,
      subject: emailObj.subject,
      message: emailObj.body
    }
    const response = await sendEmail(mailObj);
    if (response.status === 'success') {
      Swal.fire({
        icon: 'success',
        title: 'Email sent successfully!',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setEmailModalShow(false);
  }

  const sendNotificationById = async (fcmToken,message) => {
    const response = await sendNotification({ registrationToken: fcmToken, message: message });
    if (response.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Notification sent successfully!',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setNotificationModalShow(false);
  }

  const handleSendNotification = async (id,fcmToken,user) => {
    setDetail(user);
    setNotificationModalShow(true);
  };

  const handleViewDetail = async (id,group) => {
    const list = users[group];
    if(list){
      const user = list.find(x => x._id == id);
      // console.log(user);
      if(user){
        setDetail(user);
        setModalShow(true);
      }else{
        Swal.fire({
          icon: 'error',
          title: 'No record found',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };


  const handleSendEmail = async (id,email,user) => {
    if (!validateEmail(email)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid email provided',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setDetail(user);
    setEmailModalShow(true);
  };

  const handleBulkSendEmail = async () => {
    const id = key.replace('group','');
    if(!users[id]){
      Swal.fire({
        icon: 'error',
        title: 'No user found on selected group',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    
    const groupUsers = users[id];
    if(groupUsers.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'No user found on selected group',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setPercentage(0);
    setLoading(true);
    setSendList(groupUsers.length);
    const percent = Math.round(groupUsers.length/100);
    const mailList = groupUsers.map(async (user,i) => {
      const email = user.email;
      if (validateEmail(email)) {
        const mailObj = {
          email: email,
          subject: EMAIL_SUBJECT,
          message: EMAIL_MESSAGE
        }
        const response = await sendEmail(mailObj);
        if (response.status === 'success') {
          setPercentage(percentage+percent);
        }
      }
    })
    const finalAnswer = await Promise.all(mailList);
    Swal.fire({
      icon: 'success',
      title: 'Email sent successfully!',
      showConfirmButton: false,
      timer: 1500,
    });
    setLoading(false);
  };

  const handleBulkSendNotification = async () => {
    const id = key.replace('group','');
    if(!users[id]){
      Swal.fire({
        icon: 'error',
        title: 'No user found on selected group',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    
    const groupUsers = users[id];
    if(groupUsers.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'No user found on selected group',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    setPercentage(0);
    setLoading(true);
    setSendList(groupUsers.length);
    const percent = Math.round(groupUsers.length/100);
    const mailList = groupUsers.map(async (user,i) => {
      const fcmToken = user.fcmToken;
      const response = await sendNotification({ registrationToken: fcmToken, message: NOTIFICATION_MESSAGE });
      if (response.status === 'success') {
        setPercentage(percentage+percent);
      }
    })
    const finalAnswer = await Promise.all(mailList);
    Swal.fire({
      icon: 'success',
      title: 'Notification sent successfully!',
      showConfirmButton: false,
      timer: 1500,
    });
    setLoading(false);
  };

  return (
    <div className="container">
      <>
        <Header
          setIsAuthenticated={setIsAuthenticated}
          group={key}
          handleBulkSendEmail={handleBulkSendEmail}
          handleBulkSendNotification={handleBulkSendNotification}
          percentage={percentage}
          loading={loading}
        />
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
          fill
        >
          <Tab eventKey="group1" title={`Group 1 (${users["1"] ? users["1"].length : 0})`}>
            <List
              users={users["1"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group2" title={`Group 2 (${users["2"] ? users["2"].length : 0})`}>
            <List
              users={users["2"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group3" title={`Group 3 (${users["3"] ? users["3"].length : 0})`}>
            <List
              users={users["3"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group4" title={`Group 4 (${users["4"] ? users["4"].length : 0})`}>
            <List
              users={users["4"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group5" title={`Group 5 (${users["5"] ? users["5"].length : 0})`}>
            <List
              users={users["5"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group6" title={`Group 6 (${users["6"] ? users["6"].length : 0})`}>
            <List
              users={users["6"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group7" title={`Group 7 (${users["7"] ? users["7"].length : 0})`}>
            <List
              users={users["7"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
          <Tab eventKey="group8" title={`Group 8 (${users["8"] ? users["8"].length : 0})`}>
            <List
              users={users["8"]}
              handleSendNotification={handleSendNotification}
              handleSendEmail={handleSendEmail}
              handleViewDetail={handleViewDetail}
            />
          </Tab>
        </Tabs>
        <DetailModel
          user={detail}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <CustomModal
          user={detail}
          setForm={setNotificaion}
          form={notification}
          show={notificationModalShow}
          subject={false}
          onHide={() => setNotificationModalShow(false)}
        />
        <CustomModal
          user={detail}
          setForm={setEmail}
          from={email}
          show={emailModalShow}
          subject={true}
          onHide={() => setEmailModalShow(false)}
        />
      </>
    </div>
  );
};

export default Dashboard;
