import React from 'react';
import { APP_URL } from './../Constant';

export async function getUsers(){
    const apiUrl = `${APP_URL}/api/groupWiseusers`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        }
    });

    if (response.ok) {
        console.log('FCM Token stored successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error storing FCM Token on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function sendNotification(data){
    const apiUrl = `${APP_URL}/api/notification`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        console.log('FCM Token stored successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error storing FCM Token on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function sendEmail(data){
    const apiUrl = `${APP_URL}/api/sendEmail`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        console.log('Email successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error emailing on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}