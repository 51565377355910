import React from 'react';
import { Table } from 'react-bootstrap';
import { countries } from '../../Constant';
import { convertToCapital } from '../../utils/helper';

const List = ({ users, handleSendNotification, handleSendEmail, handleViewDetail }) => {
  
  return (
    <div className="contain-table">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>No.</th>
            <th>User Name</th>
            <th>Email</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Education</th>
            <th>Social</th>
            <th>Country</th>
            <th colSpan={2} className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {users && users.length > 0 ? (
            users.map((user, i) => (
              <tr key={user._id}>
                <td>{i + 1}</td>
                <td>{user.userName}</td>
                <td>{user.email}</td>
                <td>{user.age}</td>
                <td>{convertToCapital(user.gender)}</td>
                <td>{convertToCapital(user.education)}</td>
                <td>{convertToCapital(user.social)}</td>
                <td>{countries[user.country]} </td>
                <td className="text-right">
                  <button
                    onClick={() => handleViewDetail(user._id,user.group)}
                    className="button muted-button mx-1"
                  >
                    View
                  </button>
                  <button
                    onClick={() => handleSendNotification(user._id,user.fcmToken,user)}
                    className="button muted-button"
                  >
                    Send Notification
                  </button>
                </td>
                <td className="text-left">
                  <button
                    onClick={() => handleSendEmail(user._id,user.email,user)}
                    className="button muted-button"
                  >
                    Send Email
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No Users</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default List;
